<template>
  <div class="wrap__contents countries-inner">
    <div class="wrap">
      <div class="tabs">
        <div
          class="tabs__item"
          :class="{ 'is-active' : $route.params.type === 'all' }"
          @click="TRIGGER_COUNT()"
        >
          <router-link to="/account/bet/all"></router-link>
          <span>
            {{ translate(translations, 'all') }}
          </span>
        </div>

        <div
          class="tabs__item"
          :class="{ 'is-active' : $route.params.type === 'sports' }"
          @click="TRIGGER_COUNT()"
        >
          <router-link to="/account/bet/sports"></router-link>
          <span>
            {{ translate(translations, 'sports free') }}
          </span>
        </div>

        <div
          class="tabs__item"
          :class="{ 'is-active' : $route.params.type === 'inplay' }"
          @click="TRIGGER_COUNT()"
        >
          <router-link to="/account/bet/inplay"></router-link>
          <span>
            {{ translate(translations, 'sports inplay') }}
          </span>
        </div>

        <div
          class="tabs__item"
          :class="{ 'is-active' : $route.params.type === 'mini' }"
          @click="TRIGGER_COUNT()"
        >
          <router-link to="/account/bet/mini"></router-link>
          <span>
            {{ translate(translations, 'mini game') }}
          </span>
        </div>

        <div
          class="tabs__item"
          :class="{ 'is-active' : $route.params.type === 'live-casino' }"
          @click="TRIGGER_COUNT()"
        >
          <router-link to="/account/bet/live-casino"></router-link>
          <span>
            {{ translate(translations, 'live casino') }}
          </span>
        </div>

        <div
          class="tabs__item"
          :class="{ 'is-active' : $route.params.type === 'casino' }"
          @click="TRIGGER_COUNT()"
        >
          <router-link to="/account/bet/casino"></router-link>
          <span>
            {{ translate(translations, 'casino') }}
          </span>
        </div>

        <div
          class="tabs__item"
          :class="{ 'is-active' : $route.params.type === 'virtual' }"
          @click="TRIGGER_COUNT()"
        >
          <router-link to="/account/bet/virtual"></router-link>
          <span>
            {{ translate(translations, 'virtual') }}
          </span>
        </div>

        <div
          class="tabs__item"
          :class="{ 'is-active' : $route.params.type === 'slot' }"
          @click="TRIGGER_COUNT()"
        >
          <router-link to="/account/bet/slot"></router-link>
          <span>
            {{ translate(translations, 'slot game') }}
          </span>
        </div>
      </div>

      <component :is="currentTab"></component>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

// tab
import AccountBetAll from '@/views/account/AccountBetAll'
import AccountBetSports from '@/views/account/AccountBetSports'
import AccountBetInplay from '@/views/account/AccountBetInplay'
import AccountBetMini from '@/views/account/AccountBetMini'
import AccountBetLiveCasino from '@/views/account/AccountBetLiveCasino'
import AccountBetCasino from '@/views/account/AccountBetCasino'
import AccountBetVirtual from '@/views/account/AccountBetVirtual'
import AccountBetSlot from '@/views/account/AccountBetSlot'

//= translation
import { translations } from '@/assets/js/translations/Account'

export default {
  name: 'Account',

  components: {
    AccountBetAll,
    AccountBetSports,
    AccountBetInplay,
    AccountBetMini,
    AccountBetLiveCasino,
    AccountBetCasino,
    AccountBetVirtual,
    AccountBetSlot
  },

  data () {
    return {
      translations
    }
  },

  computed: {
    currentTab () {
      let currentRoute = this.$route.params.type

      return 'account-bet-' + currentRoute
    }
  },

  methods: {
    ...mapMutations('navTrigger', ['TRIGGER_COUNT'])
  }
}
</script>
