<template>
  <div class="wrap__contents acount-bet-mini-inner">
    <div class="toolbar">
      <div class="toolbar__items search">
        <search
          :translations="translations"
          :filter-by="filterBy"
          :date-by="dateBy"
          @refresh="search"
          @trigger="trigger"
          @clear="search"
        />
      </div>
    </div>

    <div class="wrap__inner">
      <check-data-list
        :is-loading="true"
        :is-empty="true"
      >
      <!-- v-if="isDataLoading || isEmpty" -->
      </check-data-list>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

//= components
import Pagination from '@/components/base/Pagination'
import Search from '@/components/base/search/Search'
import Modal from '@/components/base/Modal'
import Checkbox from '@/components/base/Checkbox'
import CheckDataList from '@/components/base/CheckDataList'
import AttachImage from '@/components/base/AttachImage'

//= mixin
import { checkAll } from '@/assets/js/mixins/base/CheckAll'
import { getListStatus } from '@/assets/js/mixins/common/GetListStatus'

//= filters
import FormatAmount from '@/assets/js/filters/FormatAmount'

//= translation
import { translations } from '@/assets/js/translations/Account'

export default {
  name: 'AccountBetCasino',

  components: {
    Pagination,
    Search,
    Modal,
    Checkbox,
    CheckDataList,
    AttachImage
  },

  filters: {
    FormatAmount
  },

  mixins: [
    checkAll,
    getListStatus
  ],

  data () {
    const params = Object.assign(
      {
        rows: 50,
        page: 1,
        filter_by: '',
        q: '',
        sort_by: 'id',
        sort: 'desc',
        date_from: '',
        date_to: ''
      },
      this.$route.query
    )

    return {
      params,
      translations,
      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false,
      showTblSettings: false,
      selected: [],
      selectAll: false,

      dateBy: [
        {
          value: 'created_at',
          display: 'created date'
        }
      ],

      filterBy: []
    }
  },
  watch: {
    '$route' (to) {
      this.route = to

      this.search(this.generate(this.$route.query))
    }
  },

  watch: {
    '$route' (to) {
      this.route = to

      this.search(this.generate(this.$route.query))
    }
  },

  computed: {
    ...mapState('account', ['accountsBet'])
  },

  created () {
    this.search()
  },

  methods: {
    ...mapActions('account', ['getBets']),

    async search (query) {
      this.params = query || this.params
      // == flags reset
      this.isEmpty = false
      if (!this.showDataLoading) {
        this.isDataLoading = true
      }

      await this.getBets({
        type: this.$route.params.type,
        params: this.params
      })

      this.isDataLoading = false
      this.showDataLoading = false

      if (!this.accountsBet.result.length) {
        this.isEmpty = true
      }
    },

    async trigger (query) {
      this.params = query || this.params
      this.$router.push({ query: this.params })
    }
  }
}
</script>
